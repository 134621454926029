<template>
	<div class="equipment">
		<div class="equipment-T">
			<div class="equipment-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="equipment-searchs">
					<!-- <inputs @inputs="inputserValue" :inputShow="true" inputPlace="请输入SN/名称查询"></inputs>
					<div class="equipment-tit-mai1-btn">
						<el-button type="danger" icon="el-icon-search">查询</el-button>
					</div> -->
				</div>
			</div>
			<div class="equipment-TR">
				<div class="equipment-searchsMai">
				</div>
			</div>
		</div>
		<div class="equipment-B">
			<el-form :inline="true" :model="pages" class="demo-form-inline">
				<el-form-item label="设备名称">
					<el-input v-model="pages.T_name" placeholder="请输入设备名称/sn" clearable></el-input>
				</el-form-item>
				<el-form-item label="分类">
					<el-select v-model="pages.T_calssid" placeholder="分类" @focus="GetClassAllApi">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.T_name" :value="item.Id" v-for="item in optionData" :key="item.Id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="数据">
					<el-select v-model="pages.T_datashow" placeholder="数据展示" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="显示" value="1"></el-option>
						<el-option label="隐藏" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="排序">
					<el-select v-model="pages.T_sort" placeholder="排序" style="width: 100px;">
						<el-option label="升序" value="0"></el-option>
						<el-option label="降序" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="启用">
					<el-select v-model="pages.T_en" placeholder="活动区域" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="启用" value="1"></el-option>
						<el-option label="停用" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="空库">
					<el-select v-model="pages.T_free" placeholder="空库" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="空库" value="1"></el-option>
						<el-option label="正常" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="Getsearch">搜索</el-button>
				</el-form-item>
			</el-form>
			<div class="equipment-B-table" v-infinite-scroll="scroll" infinite-scroll-immediate="false">
				<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border
					@selection-change="handleSelectionChange">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column type="index" width="50" label="序号">
					</el-table-column>
					<el-table-column label="SN" width="200" show-overflow-tooltip>
						<template slot-scope="scope">{{ scope.row.T_sn }}</template>
					</el-table-column>
					<el-table-column prop="T_id" label="编号" width="120" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="T_name" width="150" label="传感器名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="T_type_name" width="95" label="传感器类型" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="启/停" width="80">
						<template slot-scope="scope">
							<div v-if="scope.row.T_en==null"></div>
							<el-tag v-else :type="scope.row.T_en==1?'':'danger'">{{scope.row.T_en==1?'启用':'停用'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="空库" width="80">
						<template slot-scope="scope">
							<div v-if="scope.row.T_free==null"></div>
							<el-tag v-else :type="scope.row.T_free==1?'danger':''">{{scope.row.T_free==1?'空库':'正常'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="温度范围(°C)" width="150" show-overflow-tooltip>
						<template slot-scope="scope">
							<div style="display: flex;">
								<div>{{scope.row.T_Tlower}}</div>
								<div style="padding: 0 5px;" v-if="scope.row.T_Tlower!=null && scope.row.T_Tupper!=null"> ~ </div>
								<div>{{scope.row.T_Tupper}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="湿度范围(%)" width="150" show-overflow-tooltip>
						<template slot-scope="scope">
							<div style="display: flex;">
								<div>{{scope.row.T_RHlower}}</div>
								<div style="padding: 0 5px;" v-if="scope.row.T_RHlower!=null && scope.row.T_RHupper!=null"> ~ </div>
								<div>{{scope.row.T_RHupper}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="预警" width="70" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-tag :type="scope.row.T_enprel==1?'':'danger'">{{scope.row.T_enprel==1?'开启':'关闭'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="预警温度范围" width="120" show-overflow-tooltip>
						<template slot-scope="scope">
							<div style="display: flex;">
								<div>{{scope.row.T_tprel}}</div>
								<div style="padding: 0 5px;" v-if="scope.row.T_tprel!=null && scope.row.T_tpreu!=null"> ~ </div>
								<div>{{scope.row.T_tpreu}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="预警湿度范围" width="110" show-overflow-tooltip>
						<template slot-scope="scope">
							<div style="display: flex;">
								<div>{{scope.row.T_hprel}}</div>
								<div style="padding: 0 5px;" v-if="scope.row.T_hprel!=null && scope.row.T_hpreu!=null"> ~ </div>
								<div>{{scope.row.T_hpreu}}</div>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="name" label="数据展示" width="80">
						<template slot-scope="scope">
							<el-tag :type="scope.row.T_datashow==1?'':'danger'">{{scope.row.T_datashow==1?'显示':'屏蔽'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="展示排序 (双击编辑)" fixed="right" show-overflow-tooltip>
						<template slot-scope="scope">
							<div @dblclick="dblclick(scope.row)" style="user-select: none;cursor: pointer;">
								{{scope.row.T_sort}}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="equipment-B-btn">
				<el-row>
					<el-button v-permission="'DeviceSensor:Warning'" type="primary" @click="showDialog('报警范围',0)">报警范围</el-button>
					<el-button v-permission="'DeviceSensor:Enprel'" type="success" @click="showDialog('预警范围',1)">预警范围</el-button>
					<el-button v-permission="'DeviceSensor:En'" type="info" @click="showDialog('启用',2)">启/停</el-button>
					<el-button v-permission="'DeviceSensor:Free'" type="warning" @click="showDialog('空库',3)">空库</el-button>
					<el-button v-permission="'DeviceSensor:Datashow'" type="danger" @click="showDialog('数据展示')">数据展示</el-button>
					<el-button v-permission="'DeviceSensor:TypeSelection'" type="primary" @click="showDialogType()">类型选择</el-button>
				</el-row>
			</div>
		</div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="40%" :append-to-body="true">
			<div>
				<el-form ref="form" label-width="80px">
					<!-- 报警范围 -->
					<el-form-item label="温度范围" v-if="title=='报警范围'">
						<div style="display: flex;align-items: center;">
							<div style="display: flex;align-items: center;">
								<div>
									<el-input v-model="pages2.T_Tlower" :placeholder="placeholdershow.tlplaceholder"></el-input>
								</div>
								<div style="padding: 0 10px;">-</div>
								<div>
									<el-input v-model="pages2.T_Tupper" :placeholder="placeholdershow.tuplaceholder"></el-input>
								</div>
							</div>
							<el-popover placement="top-start" title="提示" width="200" trigger="hover"
								style="margin-left: 20px;" content=" -40.0 至 80.0 ">
								<i class="el-icon-question" slot="reference" style="font-size: 16px;"></i>
							</el-popover>
						</div>
					</el-form-item>
					<el-form-item label="湿度范围" v-if="title=='报警范围'">
						<div style="display: flex;align-items: center;">
							<div style="display: flex;align-items: center;">
								<div>
									<el-input v-model="pages2.T_RHlower" :placeholder="placeholdershow.hlplaceholder"></el-input>
								</div>
								<div style="padding: 0 10px;">-</div>
								<div>
									<el-input v-model="pages2.T_RHupper" :placeholder="placeholdershow.huplaceholder"></el-input>
								</div>
							</div>
							<el-popover placement="top-start" title="提示" width="200" trigger="hover"
								style="margin-left: 20px;" content=" -40.0 至 80.0 ">
								<i class="el-icon-question" slot="reference" style="font-size: 16px;"></i>
							</el-popover>
						</div>
					</el-form-item>
					
					<!-- 启用 -->
					<el-form-item label="启用" v-if="title=='启用'">
						<div style="display: flex;align-items: center;">
							<el-switch v-model="pages2.T_en" :inactive-value="0" :active-value="1"></el-switch>
							<el-popover placement="top-start" title="标题" width="200" trigger="hover"
								style="margin-left: 20px;" content="功能（实时、记录、报警、预警）。">
								<i class="el-icon-question" slot="reference" style="font-size: 16px;"></i>
							</el-popover>
						</div>
					</el-form-item>
					
					<!-- 数据展示 -->
					<el-form-item label="数据展示" v-if="title=='数据展示'">
						<el-radio-group v-model="form.dataShow">
							<el-radio label="0">屏蔽数据展示</el-radio>
							<el-radio label="1">正常数据展示</el-radio>
						</el-radio-group>
					</el-form-item>
					
					<!-- 预警范围 -->
					<el-form-item label="预警" v-if="title=='预警范围'">
						<div style="display: flex;align-items: center;">
							<el-switch v-model="pages2.T_enprel" :inactive-value="0" :active-value="1" @change="changeSwiper"></el-switch>
							<el-popover placement="top-start" title="提示" width="200" trigger="hover"
								style="margin-left: 20px;" content="推送预警消息，设为 空库后 不推送预警消息。">
								<div slot="reference" style="display: flex;align-items: center;">
									<i class="el-icon-question" style="font-size: 16px;"></i>
									<div style="color: red;margin-left: 10px;font-size: 12px;line-height: 20px;text-align: justify;">
										按钮默认关闭状态，点击改变按钮状态（不操作保持原来的状态）
									</div>
								</div>
							</el-popover>
						</div>
					</el-form-item>
					<el-form-item label="温度范围" v-if="title=='预警范围'">
						<div style="display: flex;align-items: center;">
							<div style="display: flex;align-items: center;">
								<div>
									<el-input v-model="pages2.T_tprel" :placeholder="placeholdershow.tlplaceholder"></el-input>
								</div>
								<div style="padding: 0 10px;">-</div>
								<div>
									<el-input v-model="pages2.T_tpreu" :placeholder="placeholdershow.tuplaceholder"></el-input>
								</div>
							</div>
							<el-popover placement="top-start" title="提示" width="200" trigger="hover"
								style="margin-left: 20px;" content="0.0  至 100.0">
								<i class="el-icon-question" slot="reference" style="font-size: 16px;"></i>
							</el-popover>
						</div>
					</el-form-item>
					<el-form-item label="湿度范围" v-if="title=='预警范围'">
						<div style="display: flex;align-items: center;">
							<div style="display: flex;align-items: center;">
								<div>
									
									<el-input v-model="pages2.T_hprel" :placeholder="placeholdershow.hlplaceholder"></el-input>
								</div>
								<div style="padding: 0 10px;">-</div>
								<div>
									<el-input v-model="pages2.T_hpreu" :placeholder="placeholdershow.huplaceholder"></el-input>
								</div>
							</div>
							<el-popover placement="top-start" title="提示" width="200" trigger="hover"
								style="margin-left: 20px;" content="0.0  至 100.0">
								<i class="el-icon-question" slot="reference" style="font-size: 16px;"></i>
							</el-popover>
						</div>
					</el-form-item>
					<!-- 空库 -->
					<el-form-item label="启用空库" v-if="title=='空库'">
						<div style="display: flex;align-items: center;">
							<el-switch v-model="pages2.T_free" :inactive-value="0" :active-value="1"></el-switch>
							<el-popover placement="top-start" title="提示" width="200" trigger="hover"
								style="margin-left: 20px;" content="1.空库（正常 实时、记录, 不报警、预警）  2.正常（正常 实时、记录、报警、预警）。">
								<div slot="reference" style="display: flex;align-items: center;">
									<i class="el-icon-question" style="font-size: 16px;"></i>
									<div style="color: red;margin-left: 10px;text-align: justify;">
										{{ msg }}
									</div>
								</div>
							</el-popover>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit(title)">{{title=='数据展示'?'提交':'传感器配置'}}</el-button>
			</span>
		</el-dialog>
		<el-dialog title="类型选择" :visible.sync="dialogVisibleType" :append-to-body="true">
			<div>
				<el-form ref="form" label-width="80px">
					<el-form-item label="类型选择">
						<el-radio-group v-model="pomosType.T_type">
							<el-radio :label="0" style="line-height: 30px;">默认</el-radio>
							<el-radio :label="item.Id" v-for="(item, index) in RadioData" :key="index"
								style="line-height: 30px;">{{ item.T_name }}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibleType = false">取 消</el-button>
				<el-button type="primary" @click="subtype">提交设置</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		DevSenList,
		DevSenEdit,
		DevSenParam
	} from "@/api/equipmentManagement/equipment.js"
	import {ClassAll} from "@/api/ClassAll.js"
	import {DevListAll} from "../../api/equipmentManagement/management.js"
	import {showDiaFrom,placeholderFun} from "@/plugins/showDiaFrom"
	export default {
		data() {
			return {
				msg:'',
				RadioData:[],
				pages:{
					T_name:'',//包含 T_name、T_sn 
					T_calssid:'',//分类 ID
					T_en:'',//启用 0 停用  1 启用  空 所有
					T_free:'',//空库 0 正常  1 空库 空 所有
					T_datashow:'',//0 屏蔽数据展示  1 正常数据展示 空:所有
					T_sort:'',//排序 0 升序 1 降序
					page:1,//页码
					page_z:20,//
				},
				optionData:[],//分类列表
				Page_size:1,
				pages1:{//1双击编辑排序  2屏蔽数据展示
					T_sn:'',
					T_id:'',
					T_sort:'',
					T_datashow :'',
				},
				pomosType:{
					T_type:0
				},
				SwiperStr:false,
				pages2:{
					// T_sn:null,//
					// T_id:null,//
					// T_Tlower:null,//温度下限
					// T_Tupper :null,//温度上限
					// T_RHlower :null,//湿度下限
					// T_RHupper:null,//湿度上限
					// T_en:null,//是否启用传感器
					// T_free:null,//监测点是否为闲置状态（空库，只监测不报警）
					// T_enprel :null,//是否启用预警   1开启  0 关闭
					// T_tprel :null,//温度预警下限  
					// T_tpreu:null,//温度预警上限
					// T_hprel :null,//湿度预警下限
					// T_hpreu :null,//温度预警上限
				},
				placeholdershow:{},
				form: {
					dataShow: '0',
				},
				title: '',
				dialogVisible: false,
				dialogVisibleType: false,
				multipleSelection: [],
				tableData: [],
				resIts:null
			}
		},
		mounted() {
			this.asSerFun()
			
		},
		methods: {
			dblclick(data) {//双击
				this.$prompt('请输入排序号：', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValue:`${data.T_sort}`
					// inputPattern: /^[+]{0,1}(\d+)$/,
					// inputErrorMessage: '序号格式不正确'
				}).then(async({
					value
				}) => {
					this.pages1.T_sn = data.T_sn
					this.pages1.T_id = data.T_id
					this.pages1.T_sort = value
					var res = await this.GetDevSenEditApi()
					if(res.data.Code===200){
						this.tableData = []
						this.pages.page = 1
						this.Page_size = 1
						this.$message.success('编辑成功')
						this.asSerFun()
					}	
					////console.log('返回res',res)
				}).catch(() => {
					this.$message.info('取消编辑序号')
				});
			},
			GetDevSenEditApi(){
				return new Promise(resolve=>{
					DevSenEdit(this.pages1).then(res=>{
						////console.log('编辑',res)
						resolve(res)
					})
				})
			},
			GetClassAllApi(){//分类
				ClassAll({}).then(res=>{
					if(res.data.Code){
						 this.optionData = res.data.Data.Data
						////console.log('分类',res,this.optionData)
					}
				})
			},
			async scroll() {//触底加载
				////console.log('滚动',this.pages.page)
				if(this.pages.page == this.Page_size){
					this.$message.info('哎呀，已经到底了')
					return
				}
				this.pages.page = this.pages.page+1
				this.asSerFun()

			},
			async asSerFun(){
				var res = await this.GetDevSenListApi()
				if(res.data.Code===200 && res.data.Data.Data!=null){
					this.Page_size = res.data.Data.Page_size
					this.tableData = [...this.tableData,...res.data.Data.Data]
				}
			},
			GetDevSenListApi(){//列表
				return new Promise(resolve=>{
					DevSenList(this.pages).then(res=>{
						resolve(res)
					})
				})
			},
			Getsearch(){//搜索
				this.tableData = []
				this.pages.page = 1
				this.Page_size = 1
				this.asSerFun()
			},
			
			
			handleSelectionChange(val) { //全选的值
				this.multipleSelection = val;
			},
			changeSwiper(e){
				this.SwiperStr = true
				////console.log('触发',e,this.SwiperStr)	
			},
			// 类型选择
			showDialogType(){
				if(this.multipleSelection.length==0){
					this.$message.error('请至少选择一项进行操作')
					return
				}else{
					this.dialogVisibleType = true
					this.getDevListAllApi()
				}
				
			},
			getDevListAllApi() {
				DevListAll({}).then(res => {
					if (res.data.Code == 200) {
						this.RadioData = res.data.Data.Data
					}
				})
			},
			async subtype(){
				var arr = this.multipleSelection
				var suc = 0
				var fai = 0
				const loadings2 = this.$loading({
					text:'正在处理，请稍后...',
				})
				this.dialogVisibleType = false
				for (let i = 0; i < arr.length; i++) {
					var res = await new Promise(resolve=>{
						DevSenEdit({
							T_sn:arr[i].T_sn,
							T_id:arr[i].T_id,
							...this.pomosType
						}).then(res=>{
							setTimeout(() => {
								resolve(res)
							}, 2000);
						})
					}) 
					if(res.data.Code===200){
						suc = suc+1
					}else{
						fai = fai+1
					}
					
					loadings2.text = `已完成${i+1}/${arr.length}`
					if(arr.length==suc+fai){
						this.tableData = []
						this.pages.page = 1
						this.Page_size = 1
						this.scroll()
						this.$message.success(`批量操作,成功${suc}个,失败${fai}个`)
						this.pages2 = {}
						loadings2.close();
						this.asSerFun()
					}
				}
			},
			showDialog(e,i) {
				console.log('不等于数据展示',e,i)
				var that = this
				if (e!='数据展示') {
					////console.log('不等于数据展示')
					that.pages2 = showDiaFrom(i)
				}
				if(this.multipleSelection.length==0){
					this.$message.error('请至少选择一项进行操作')
					return
				}else{
					Object.keys(this.pages2).forEach(item=>{this.pages2[item] = ''})
					this.title = e
					
					if(e=='报警范围' || e=='预警范围'){
						this.placeholdershow = placeholderFun();
						this.screenFun(e)
					}
					if(e=='空库'){
						console.log('e',e,this.multipleSelection,this.pages2.T_free)
						const it = this.multipleSelection[0].T_free
						const arrMap = []
						this.multipleSelection.forEach(item=>{arrMap.push(item.T_free)})
						const b = arrMap.every(item => item==it)
						console.log('???',b,it)
						if(b){
							this.pages2.T_free = it
							this.msg = ''
						}else{
							this.pages2.T_free = ''
							this.msg = '选择状态不同，默认状态(不操作，不修改原状态)'
						}
					}
					this.dialogVisible = true
				}
			},
			//筛选出选中的温湿度是否相同，相同就填入输入框，反之显示所选温湿度不同
			screenFun(e){
				// ////console.log('this.multipleSelection',this.multipleSelection,this.pages2)
				const arr = [...this.multipleSelection]
				let tTop = [],tBut = [],hTop = [],hBut = []
				arr.forEach(item=>{
					console.log('**',item)
					tTop.push(e=='报警范围'?item.T_Tupper:item.T_tpreu)
					tBut.push(e=='报警范围'?item.T_Tlower:item.T_tprel)
					hTop.push(e=='报警范围'?item.T_RHupper:item.T_hpreu)
					hBut.push(e=='报警范围'?item.T_RHlower:item.T_hprel)
				})
				tTop = [...new Set(tTop)]
				tBut = [...new Set(tBut)]
				hTop = [...new Set(hTop)]
				hBut = [...new Set(hBut)]

				
				if (e=='报警范围') {
					if (tTop.length==1) {this.pages2.T_Tupper = tTop[0]}else{this.pages2.T_Tupper = '';this.placeholdershow.tuplaceholder='所选温度上限不同'}
					if (tBut.length==1) {this.pages2.T_Tlower = tBut[0]}else{this.pages2.T_Tlower = '';this.placeholdershow.tlplaceholder='所选温度下限不同'}
					if (hTop.length==1) {this.pages2.T_RHupper = hTop[0]}else{this.pages2.T_RHupper = '';this.placeholdershow.huplaceholder='所选湿度上限不同'}
					if (hBut.length==1) {this.pages2.T_RHlower = hBut[0]}else{this.pages2.T_RHlower = '';this.placeholdershow.hlplaceholder='所选湿度下限不同'}
				} else {
					if (tTop.length==1) {this.pages2.T_tpreu = tTop[0]}else{this.pages2.T_tpreu = '';this.placeholdershow.tuplaceholder='所选温度上限不同'}
					if (tBut.length==1) {this.pages2.T_tprel = tBut[0]}else{this.pages2.T_tprel = '';this.placeholdershow.tlplaceholder='所选温度下限不同'}
					if (hTop.length==1) {this.pages2.T_hpreu = hTop[0]}else{this.pages2.T_hpreu = '';this.placeholdershow.huplaceholder='所选湿度上限不同'}
					if (hBut.length==1) {this.pages2.T_hprel = hBut[0]}else{this.pages2.T_hprel = '';this.placeholdershow.hlplaceholder='所选湿度下限不同'}
				}
				
				
			},
			
			async onSubmit(e) {
				if(e=='预警范围'){
					if(!this.SwiperStr){
						this.pages2.T_enprel = ''
						////console.log('=====')
					}
				}
				if(e=='启用'){
					if(this.pages2.T_en == ''){
						this.pages2.T_en = 0
					}
				}
				switch(e){
					case '数据展示':
						var arr = this.multipleSelection
						this.pages1.T_sort = ''
						const loadings1 = this.$loading();
						this.dialogVisible = false
						var suc = 0
						var fai = 0
						arr.forEach( async (item)=>{
							this.pages1.T_datashow = this.form.dataShow
							this.pages1.T_sn = item.T_sn
							this.pages1.T_id = item.T_id
							var res = await this.GetDevSenEditApi()
							if(res.data.Code===200){
								suc = suc+1
							}else{
								fai = fai+1
							}
							if(arr.length==suc+fai){
								this.tableData = []
								this.pages.page = 1
								this.Page_size = 1
								this.scroll()
								this.$message.success(`批量操作,成功${suc}个,失败${fai}个`)
								loadings1.close();
							}
						})
						break
					default:
						this.switchFun()
				}
				this.asSerFun()
			},
			async switchFun(){
				let that = this
				var arr = this.multipleSelection
				var suc = 0
				var fai = 0
				const loadings2 = this.$loading({
					text:'正在处理，请稍后...',
				})
				this.dialogVisible = false
				this.SwiperStr = false
				for (let i = 0; i < arr.length; i++) {
					this.pages2.T_sn = arr[i].T_sn
					this.pages2.T_id = arr[i].T_id
					
					var res = await that.getForPromise()
					
					////console.log('=============',i,res)
					
					if(res.data.Code===200){
						suc = suc+1
					}else{
						fai = fai+1
					}
					loadings2.text = `已完成${i+1}/${arr.length}`
					if(arr.length==suc+fai){
						this.tableData = []
						this.pages.page = 1
						this.Page_size = 1
						this.scroll()
						this.$message.success(`批量操作,成功${suc}个,失败${fai}个`)
						this.pages2 = {}
						loadings2.close();
						this.asSerFun()
					}
				}
			},
			
			getForPromise(){
				let that = this
				return new Promise(async(resolve) => {
					for (let j = 0; j < 3; j++) {
						that.resIts = await that.GetDevSenParamApi()
					}
					resolve(that.resIts)
				})
			},
			GetDevSenParamApi(){
				return new Promise((resolve) => {
					setTimeout(async() => {
						DevSenParam(this.pages2).then(res=>{
							resolve(res)
						})
					},500)
				})
			},
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/equipmentManagement/equipment.scss';
</style>
