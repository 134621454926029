const arr = [
    {T_Tlower:'',T_Tupper:'',T_RHlower:'',T_RHupper:''},
    {T_enprel:'',T_tprel:'',T_tpreu:'',T_hprel:'',T_hpreu:''},
    {T_en:''},
    {T_free:''},
]
export function showDiaFrom(e){
    //console.log('触发方法',e)
    return arr[e]
}
export function placeholderFun(){
    return {tlplaceholder:'温度上限',tuplaceholder:'温度下限',hlplaceholder:'湿度上限',huplaceholder:'湿度上限'}
}