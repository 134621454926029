// 登录接口
import {
	axios
} from '../../utils/http.js'

const DevSenApi = {
	DevSenList: '/v3/DeviceSensor/Manage_List',//列表
	DevSenEdit: '/v3/DeviceSensor/Edit',//1双击编辑排序  2屏蔽数据展示
	DevSenParam: '/v3/DeviceSensor/Parameter_Pu',
}
//1双击编辑排序  2屏蔽数据展示
export function DevSenParam(data) {
	return axios({
		url: DevSenApi.DevSenParam,
		method: 'post',
		data:data
	})
}
//1双击编辑排序  2屏蔽数据展示
export function DevSenEdit(data) {
	return axios({
		url: DevSenApi.DevSenEdit,
		method: 'post',
		data:data
	})
}

//单个传感器设置修改-视图订阅-屏蔽数据-数据排序
export function DevSenList(data) {
	return axios({
		url: DevSenApi.DevSenList,
		method: 'post',
		data:data
	})
}